import React from 'react';
import { GetAboutBlockEN } from '../../content/pages/foundation/content/aboutBlock/en/GetAboutBlock';
import { GetAboutBlockFR } from '../../content/pages/foundation/content/aboutBlock/fr/GetAboutBlock';
import './about.scss'

const About = ({lang}) => {
    const content = lang === 'fr' ? GetAboutBlockFR() : GetAboutBlockEN()
    return (
        <div className="content-container">
            <h2   
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease">{content.foundationTitle}</h2>
            <p className="quotes">{content.foundationQuotes}</p>
            <p className="quotes-author">{content.foundationQuotesAuthor + ',' + ' '}</p>
            <p className="quotes-author-role">{content.foundationQuotesRole}</p>
            <a className="quotes-link" href={content.foundationQuotesLink}>{content.foundationQuotesLink}</a>
            <p className="quotes-date" >{content.foundationQuotesDate}</p>
            <p className="quotes-content-second">{content.foundationContentFirst}</p>
            <p className="quotes-subtitle">{content.foundationContentSubTitle}</p>
            <p className="quotes-content-second">{content.foundationContentSecond}</p>
            <p className="quotes-content-block">{content.foundationContentBlock}</p>
        </div>
    );
};

export default About;