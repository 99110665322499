import React, { useState } from 'react';
import { GetTeamBlockEN } from '../../content/pages/foundation/content/teamBlock/en/GetTeamBlock';
import { GetTeamBlockFR } from '../../content/pages/foundation/content/teamBlock/fr/getTeamBlock';
import './team.scss'
import BackgroundImage from 'gatsby-background-image'

const Team = ({lang}) => {
    const content = lang === 'fr' ? GetTeamBlockFR() : GetTeamBlockEN() 
    const [overlay, setOverlay] = useState([false, -1])
    return (
        <div className="content-container">
            <h2
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >{content.teamTitle}</h2>
            <p>{content.teamSubTitle}</p>
            <div className="team-card-container">
            {content.team.map((e,i)=> (
                <div className="team-member-content">
                    <BackgroundImage className="team-card" fixed={e.image.childImageSharp.fixed} key={i} onClick={()=>setOverlay([true, i])}>
                        <div className="team-card-info">
                            <p className="team-member-name">{e.name}</p>
                            <p className="team-member-role">{e.role}</p>                    
                        </div>
                    </BackgroundImage>
                    <div className="overlay" style={{display: overlay && overlay[1] === i ? 'flex' : 'none'}}></div>
                    <div className="overlay-content" onClick={() => setOverlay([false, -1])} style={{display: overlay && overlay[1] === i ? 'flex' : 'none'}}>
                        <h4 className="team-member-name">{e.name}</h4>
                        <p className="team-member-role">{e.role}</p>
                        <p className="team-member-about">{e.about}</p>
                        <a className="team-member-link" href={e.link}>{e.link}</a>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
};

export default Team;