import {useStaticQuery, graphql} from 'gatsby';

export const GetTeamBlockEN = () =>  {
	
	const content = useStaticQuery(graphql`
	query TeamBlockEN {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "en"}}, fields: {collection: {eq: "FoundationTeamBlock"}}}
		) {
		  edges {
			node {
			  frontmatter {
                teamTitle
                teamSubTitle
                team {
                    name
                    role
                    image{
						childImageSharp {
							fixed {
							  src
							}
						}
					}
                    about
                    link
                }
			  }
			}
		  }
		}
	  }
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};