import React, {useContext} from 'react';
import Layout from '../components/template/layout';
import LangContext from '../components/context/langContext';
import About from '../components/foundation/About';
import Team from '../components/foundation/Team';
import Partners from '../components/foundation/Partners';
import { GetFoundationSeoEN } from '../content/pages/foundation/seo/en/getFoundationSeo';
import { GetFoundationSeoFR } from '../content/pages/foundation/seo/fr/getFoundationSeo';


const Foundation = () => {
    const ctx = useContext(LangContext)
    const content = ctx.lang === 'fr' ? GetFoundationSeoFR() : GetFoundationSeoEN() 
    return (
        <Layout
            title={content.pageTitle}
            description={content.pageDescription}
            lang={ctx.lang}
            page="foundation"
        >
                <About 
                    lang={ctx.lang}
                />

                <Team 
                    lang={ctx.lang}
                />
                <Partners 
                    lang={ctx.lang}
                />
        </Layout>
    );
};

export default Foundation;