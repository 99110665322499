import React from 'react';
import { GetPartnersBlockEN } from '../../content/pages/foundation/content/partnersBlock/en/GetPartners';
import { GetPartnersBlockFR } from '../../content/pages/foundation/content/partnersBlock/fr/GetPartners';
import Img from 'gatsby-image';
import './partners.scss';

const Partners = ({lang}) => {
    const content = lang === 'fr' ? GetPartnersBlockFR() : GetPartnersBlockEN()
    return (
        <div className="content-container">
            <h2
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >{content.partnersTitle}</h2>
            <div className="partners-logo-container">
                {content.partners.map((e,i)=> (
                    <a href={e.link} className={e.name}>
                        <Img fixed={e.image.childImageSharp.fixed} />
                    </a>
                ))}
            </div>

        </div>
    );
};

export default Partners;