import {useStaticQuery, graphql} from 'gatsby';


export const GetAboutBlockFR = () =>  {
	
	const content = useStaticQuery(graphql`
	query AboutBlockFR {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "fr"}}, fields: {collection: {eq: "FoundationAboutBlock"}}}
		) {
		  edges {
			node {
			  frontmatter {
                foundationTitle
                foundationQuotes
                foundationQuotesAuthor
                foundationQuotesRole
                foundationQuotesLink
                foundationQuotesDate
                foundationContentFirst
                foundationContentSubTitle
                foundationContentSecond
                foundationContentBlock
			  }
			}
		  }
		}
	  }
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};